@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .dark-transit{
        @apply transition-all ease-in;
    }
    .top-navbar{
        @apply fixed top-0 w-screen dark:bg-black/60 backdrop-blur-md bg-white/70 flex justify-between dark:text-white dark-transit;
    }
    .bottom-navbar{
        @apply fixed bottom-0 w-screen flex dark:bg-black/50 backdrop-blur bg-white/70 dark:text-white dark-transit;
    }
    .bottom-nav-cards{
        @apply w-3/4 transition-all ease-in;
    }
    /* not used below */
    .bottom-nav-cards-active{
        @apply dark:text-white dark:bg-gradient-to-t dark:from-slate-600 dark:to-black dark:rounded-3xl bg-gradient-to-t from-green-100 to-white rounded-2xl;
    }
    .page-content{
        @apply pt-16 h-screen w-screen overflow-y-scroll overflow-x-hidden bg-white dark:bg-black text-black dark:text-white dark-transit no-highlights;
    }
    .navbar{
        @apply fixed left-0 top-0 h-5/6 w-3/4 bg-white text-black m-5 mt-10 pb-10 rounded-xl
        transition-all duration-500 z-50;
    }
    .navbar-hidden {
        @apply fixed -left-3/4 top-0 h-5/6 w-3/4 bg-white/80 backdrop-blur-sm text-black mt-10 pb-10 rounded-xl
        transition-all ease-in-out duration-300 z-50;
    }
    .overlay{
        @apply fixed top-0 h-screen w-screen bg-black/30 backdrop-blur-sm z-40 transition-opacity duration-700;
    }
    .rental-cards{
        @apply bg-gradient-to-r from-cyan-200 to-cyan-500 dark:from-gray-200 dark:to-emerald-200 ml-5 mr-1 my-3 rounded-2xl 
        shadow-md h-[10.5rem] block overflow-hidden transition-all;
    }
    .rental-cards-touch{
        @apply text-white mt-[7.5rem] ml-4 absolute bg-gradient-to-tr from-green-500 to-green-900 rounded-md px-2 py-1;
    }
    .tours-cards{
        @apply bg-gradient-to-r from-slate-100 to-zinc-300 dark:from-slate-800 dark:to-zinc-900 ml-5 mr-1 my-3 rounded-2xl shadow-md h-[10.5rem] block;
    }
    .sidebar-items{
        @apply font-medium text-base flex p-2 mx-3;
    }
    .sidebar-items-active{
        @apply font-medium text-base bg-cyan-500 flex p-2 mx-3 rounded-lg text-white dark:bg-green-400;
    }
    .sidebar-partner{
        @apply p-2 w-10/12 text-lg
        bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl text-white;
    }
    .no-highlights{
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .tour-cards-highlight{
      @apply ml-2.5 font-poppins absolute text-white font-bold bg-black/50 px-3 py-1 rounded-lg;
    }
    .tour-cards-bg{
        @apply object-cover tour-cards-anim;
    }
    .tour-cards-anim{
        @apply transition-all duration-500;
    }
    .tour-cards-touch{
        @apply text-white absolute font-bold bg-black/50 py-3 px-5 mx-[3rem] my-[3.5rem] 
        rounded-2xl tour-cards-anim;
    }
    .na-popup{
        @apply flex absolute h-2/4 w-full font-poppins z-40 transition-opacity duration-500;
    }
    .tour-touch{
        @apply absolute mt-[4rem] text-white font-bold bg-black/70 mx-auto
        py-3 px-5 rounded-2xl tour-cards-anim;
    }
    .rental-listing{
    @apply bg-white rounded-xl object-cover 
    dark:bg-gradient-to-br dark:from-zinc-500 dark:via-zinc-300 dark:to-zinc-400
    transition-opacity;
    }
    .rental-touch{
    @apply absolute mt-[2rem] text-white font-medium py-3 px-5 mx-auto bg-black/70 
    transition-opacity rounded-2xl;
    }
}

